import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

import { encode } from "js-base64";

import Header from "src/components/headers/headerFissuration-generale";
import Ariane from "src/components/ariane";
import Footer from "src/components/footers/footerFissuration-generale";

import FormExpert from "../../components/formulaires/formExpert";

import Reassurance from "../../components/reassurance";
import CatNat from "src/components/cat-nat";

// import ListePages from "../../components/shortcodes/experts/ListePagesEdieux-Expertise";

////Intégration Shortcodes
import Europe1Juin2023Fissures from "src/components/shortcodes/Europe1Juin2023Fissures";
import Europe1Juin2023FissuresIMG from "src/components/shortcodes/Europe1Juin2023FissuresIMG";
import CTAExpertPresChezVous from "src/components/shortcodes/CTAExpertPresChezVous";
import CTAKitFissuration from "src/components/shortcodes/CTAKitFissuration";

// CTA Expert
import CTAOpengroupe from "src/components/shortcodes/cta/CTAOpengroupe";
import CTAEdieuxExpertise from "src/components/shortcodes/cta/CTAEdieuxExpertise";
import CTADuboisExpertise from "src/components/shortcodes/cta/CTADuboisExpertise";
import CTACabinetE2PPloixExpertise from "src/components/shortcodes/cta/CTACabinetE2PPloixExpertise";
import CTALunoaExpertConseilBenamar from "src/components/shortcodes/cta/CTALunoaExpertConseilBenamar";
import CTAAvayahJeromeGallaud from "src/components/shortcodes/cta/CTAAvayahJeromeGallaud";
import CTACabinetLespagnol from "src/components/shortcodes/cta/CTACabinetLespagnol";
import CTAEGExpabatRoux from "src/components/shortcodes/cta/CTAEGExpabatRoux";
import CTAVincentMoreau from "src/components/shortcodes/cta/CTAVincentMoreau";
import CTABatExpert34 from "src/components/shortcodes/cta/CTABatExpert34";
import CTABenoitToussaint from "src/components/shortcodes/cta/CTABenoitToussaint";
import CTAMathieuMaillardGers from "src/components/shortcodes/cta/CTAMathieuMaillardGers";
import CTANouryExpertises from "src/components/shortcodes/cta/CTANouryExpertises";

// CTA Batiment
import CTARenfortSolutions from "src/components/shortcodes/cta/CTARenfortSolutions";
import CTAGeoStudy from "src/components/shortcodes/cta/CTAGeoStudy";
import CTA1GSolutions from "src/components/shortcodes/cta/CTA1GSolutions";
import CTA2RExpertise from "src/components/shortcodes/cta/CTA2RExpertise";

const shortcodes = {
  CTAExpertPresChezVous,
  Europe1Juin2023Fissures,
  Europe1Juin2023FissuresIMG,
  CTAOpengroupe,
  CTAEdieuxExpertise,
  CTADuboisExpertise,
  CTACabinetE2PPloixExpertise,
  CTALunoaExpertConseilBenamar,
  CTAAvayahJeromeGallaud,
  CTACabinetLespagnol,
  CTAEGExpabatRoux,
  CTAVincentMoreau,
  CTABatExpert34,
  CTABenoitToussaint,
  CTAMathieuMaillardGers,
  CTANouryExpertises,
  CTARenfortSolutions,
  CTAKitFissuration,
  CTAGeoStudy,
  CTA1GSolutions,
  CTA2RExpertise,
};
////

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  const encryptedMail = encode("cabineteam2@gmail.com");
  const moyen = "Formulaire Koudepouce Geo Mathieu-Maillard-Gers";

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
        <Ariane fil_ariane={fil_ariane} />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                <div className="relative py-16 bg-white overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                      <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                          {title}
                        </span>
                      </h1>
                      {excerpt.includes("null") ? (
                        <p className="mt-8 mb-8 text-xl text-gray-500 leading-8"></p>
                      ) : (
                        <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
                          {excerpt}
                        </p>
                      )}

                      {slug.includes("/maison/catastrophe-naturelle/") ? (
                        <CatNat slug={slug} />
                      ) : (
                        <GatsbyImage
                          className="rounded-lg shadow-lg"
                          alt={thumbnailText}
                          image={getImage(embeddedImagesLocal[0])}
                        />
                      )}
                    </div>

                    <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer
                          className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto"
                          remoteImages={embeddedImagesRemote}
                          localImages={embeddedImagesLocal}
                        >
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>

                    {/* <ListePages /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="self-start top-16 col-span-1 p-4 lg:py-16 lg:w-1/3">
          <div className="bg-white ">
            <div className="border border-gray-200 mb-8 rounded-lg bg-white shadow-lg flex flex-col flex-grow text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
              <div className="text-center mb-4 text-xl font-bold">
                <p>Votre Expert Bâtiment proche de chez vous</p>
              </div>
              <a href="/maison/fissures-maison/">
                <div className="mx-auto h-40 w-40 rounded-full">
                  <StaticImage
                    className="mx-auto h-40 w-40 rounded-full"
                    src="../../markdown-pages/experts/mathieu-maillard-gers/Mathieu-M-Gers.jpg"
                    alt=""
                  />
                </div>
                <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">
                  Mathieu M
                </h3>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  {/* <strong>Mathieu M</strong><br /> */}
                  Spécialiste des fissures
                  <br />
                  100% indépendant des assurances
                  <br />
                  Interventions dans le Gers (32)
                </p>
              </a>

              <FormExpert encryptedMail={encryptedMail} moyen={moyen} />
            </div>
          </div>

          <Reassurance />
        </aside>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;
